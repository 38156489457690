import { useCallback, useState, useEffect, useRef } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import Cookies from "universal-cookie";
import { format } from "date-fns";
import {
  FaFileDownload as FileIcon,
  FaAngleDown as ArrowDown,
  FaAngleUp as ArrowUp,
} from "react-icons/fa";
import { AiOutlineCheck } from "react-icons/ai";
import { HiDocumentDownload } from "react-icons/hi";
import { confirmAlert } from "react-confirm-alert";
import { loadingAnimation } from "../../components/scripts/functions";
import { IoClose } from "react-icons/io5";

import {
  URL,
  token,
  LoadingIcon,
  SuccessMessage,
  customSelectStyles,
} from "../../components/Utils";
import { Title, LoadingTitle } from "../../components/Title";
import ToggleSwitch from "../../components/ToggleSwitch";
import "../../stylesheets/ShipmentInfo.scss";
import { toast } from "react-toastify";

import Select from "react-select";
import Uploader from "../../components/inputs/Uploader";
import { DeliveryOrder } from "./DeliveryOrder";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import BackwardArrow from '../../assets/nav-bar-icons/arrow-left.svg';
import ForwardArrow from '../../assets/nav-bar-icons/arrow-right.svg';

export const ShipmentInfo = () => {
  const cookies = new Cookies();
  const [shipment, setShipment] = useState();
  const [loading, setLoading] = useState(false);

  const [contentDisplay1, setContentDisplay1] = useState([]);
  const [opened1, setOpened1] = useState(false);

  const [contentDisplay2, setContentDisplay2] = useState([]);
  const [opened2, setOpened2] = useState(false);

  const [selectedDate, setSelectedDate] = useState();
  const [contentDisplay3, setContentDisplay3] = useState([]);
  const [opened3, setOpened3] = useState(false);

  const [availableExams, setAvailableExams] = useState([
    {
      exam: "FDA Under PGA Review",
      value: "REVIEW",
      active: false,
    },
    {
      exam: "FDA Doc Review",
      value: "DOC REVIEW",
      active: false,
    },
    {
      exam: "FDA Exam",
      value: "EXAM",
      active: false,
    },
    {
      exam: "FDA May Proceed",
      value: "MAY PROCEED",
      active: false,
    },
  ]);
  const [doError, setDoError] = useState([]);

  /**Completed steps */
  const [step1, setStep1] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { filteredShipmentIds, filter } = location.state || {};

  const initialIndex = filteredShipmentIds
    ? filteredShipmentIds.indexOf(parseInt(id, 10))
    : 0;

  const [currentShipmentIndex, setCurrentShipmentIndex] = useState(initialIndex);

  const checkDateValidity = (date) => {
    const date_parsed = new Date(date);
    return !isNaN(date_parsed.getTime()) && date !== "0000-00-00";
  };

  const fetchShipmentData = async (shipmentId) => {
    setLoading(true);
    try {
      const apiURL = `${URL}/admin/shipments/details/${shipmentId}`;
      const response = await fetch(apiURL, {
        method: "GET",
        headers: { Authorization: `Bearer ${token()}` },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch: ${response.status}`);
      }

      const data = await response.json();
      setShipment(data.shipment);
    } catch (e) {
      console.error("Error fetching shipment data", e);
      toast.error("Unable to fetch shipment data, please try again later.");
    } finally {
      setLoading(false);
    }
  };


  const handleNavigation = (direction) => {
    if (!filteredShipmentIds || filteredShipmentIds.length <= 1) return;

    if (direction === 'prev') {
      const prevIndex =
        currentShipmentIndex === 0
          ? filteredShipmentIds.length - 1
          : currentShipmentIndex - 1;
      setCurrentShipmentIndex(prevIndex);
    } else if (direction === 'next') {
      const nextIndex =
        currentShipmentIndex === filteredShipmentIds.length - 1
          ? 0
          : currentShipmentIndex + 1;
      setCurrentShipmentIndex(nextIndex);
    }
  };

  useEffect(() => {
    if (filteredShipmentIds && filteredShipmentIds.length > 0) {
      fetchShipmentData(filteredShipmentIds[currentShipmentIndex]);

      if (location.pathname !== `/shipments/edit/${filteredShipmentIds[currentShipmentIndex]}`) {
        navigate(`/shipments/edit/${filteredShipmentIds[currentShipmentIndex]}`, {
          state: { filteredShipmentIds, filter },
        });
      }
    }
  }, [filteredShipmentIds, currentShipmentIndex, filter]);

  useEffect(() => {
    if (shipment) {
      setStep1(shipment.isf_transaction_no != null);
    }
  }, [shipment]);

  const shouldShowButtons = filter && filteredShipmentIds?.length > 1;

  const [step2, setStep2] = useState(false);
  const [step3, setStep3] = useState(false);

  /* Styles */
  const greenAndBoldText = {
    color: "var(--green)",
    fontWeight: "bolder",
  };
  const greenAndBoldCircle = {
    border: "2px solid var(--green)",
    backgroundColor: "var(--global-green)",
  };


  const getShipmentData = async () => {
    setLoading(true);
    try {
      const resp = await fetch(`${URL}/admin/shipments/details/${id}`, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token()}`,
        },
      });
      const data = await resp.json();
      let master_bill_result = "";
      let house_bill_result = "";

      if (data?.shipment?.bill_lading?.length > 0) {
        data?.shipment?.bill_lading?.forEach((bill, index) => {
          if (bill?.is_master_bill_lading) {
            master_bill_result += `${bill?.bill_lading}, `;
          } else {
            house_bill_result += `${bill?.bill_lading}, `;
          }
        });

        master_bill_result = master_bill_result?.slice(0, -2);
        house_bill_result = house_bill_result?.slice(0, -2);
      }
      data.shipment.master_bill_lading = master_bill_result;
      data.shipment.house_bill_lading = house_bill_result;

      let supplementaryDocumentArr = data?.shipment?.supplementary_documents;

      if (supplementaryDocumentArr?.length > 0) {
        supplementaryDocumentArr.map((item) => {
          if (!item?.deleted_at) {
            setSupplementaryFileArray((oldArray) => [...oldArray, item]);
          }
        });
      }
      setAditionalDelivery(0);
      setShipment(data.shipment);
      if (
        data.shipment.status == "AWAITING ARRIVAL" &&
        (!data.shipment.isf_transaction_no ||
          data.shipment.isf_transaction_no == "")
      ) {
        toggleMenu1();
      } else if (
        data.shipment.status != "RELEASED" &&
        data.shipment.status != "UNDER CBP REVIEW"
      ) {
        toggleMenu2();
      } else {
        toggleMenu3();
      }

      if (data.shipment.fda_review) {
        let exams = availableExams;
        exams.map((e) => {
          if (e.value == data.shipment.fda_review) {
            e.active = true;
          }
          return e;
        });
        setAvailableExams(exams);
      } else {
        let exams = availableExams;
        exams.map((e) => {
          e.active = false;
          return e;
        });
        setAvailableExams(exams);
      }

      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };


  useEffect(() => {
    getShipmentData();
  }, []);

  const toggleMenu1 = () => {
    if (opened1) {
      setContentDisplay1({
        display: "none",
      });
      setOpened1(false);
    } else {
      setContentDisplay1({
        display: "block",
        marginBottom: "1rem",
        opacity: 1,
      });
      setOpened1(true);
    }
  };

  const toggleMenu2 = () => {
    if (opened2) {
      setContentDisplay2({
        display: "none",
      });
      setOpened2(false);
    } else {
      setContentDisplay2({
        display: "block",
        marginBottom: "1rem",
        opacity: 1,
      });
      setOpened2(true);
    }
  };

  const toggleMenu3 = () => {
    if (opened3) {
      setContentDisplay3({
        display: "none",
      });
      setOpened3(false);
    } else {
      setContentDisplay3({
        display: "block",
        marginBottom: "1rem",
        opacity: "1",
      });
      setOpened3(true);
    }
  };

  const containerNumbers = (containers) => {
    return (
      <p style={{ wordBreak: "break-word" }}>
        {containers.map((container, index) => {
          return (
            <span key={index}>
              {container.container_no}, <b></b>
            </span>
          );
        })}
      </p>
    );
  };

  const removeFromHomePage = async () => {
    let aux = { ...shipment };
    let obj = {
      show_on_home: aux.show_on_home == 0,
    };

    try {
      const resp = await fetch(`${URL}/admin/shipments/${id}/show_on_home`, {
        method: "POST",
        headers: {
          "content-type": "appilcation/json",
          Authorization: `Bearer ${token()}`,
        },
        body: JSON.stringify(obj),
      });

      const data = await resp.json();
      if (resp.status == 200) {
        aux.show_on_home == 0 ? (aux.show_on_home = 1) : (aux.show_on_home = 0);
      } else {
        toast.error(
          `Error: ${resp.status}. Could not complete request. Please, try again in a few minutes!`
        );
      }
    } catch (e) {
      console.log(e);
      toast.error(
        `Could not complete request. Please, try again in a few minutes!`
      );
    }
    setShipment(aux);
  };

  const getFileName = (path) => {
    if (path != null) {
      let split = path.split("/");
      return split[split.length - 1];
    } else {
      return "no path specified.";
    }
  };

  const [typeApproval, setTypeApproval] = useState([]);

  const sortDocuments = (documents) => {
    const sortedDocs = documents.sort((a, b) => {
      if (a.deleted_at === null && b.deleted_at !== null) {
        return -1;
      }
      if (a.deleted_at !== null && b.deleted_at === null) {
        return 1;
      }
      return 0;
    });

    return sortedDocs;
  };

  const changeStatus = async (value) => {
    try {
      const resp = await fetch(`${URL}/admin/shipments/${id}/update_status`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token()}`,
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          status: value,
        }),
      });

      if (resp.status == 200) {
        // const data = await resp.json();
      } else {
        console.error(`HTTP error: ${resp.status}`);
        toast.error(
          `Error: ${resp.status}. Could not complete request. Please, try again in a few minutes!`
        );
      }
    } catch (err) {
      console.error(`Fetch error: ${err}`);
      toast.error(
        `Could not complete request. Please, try again in a few minutes!`
      );
    }
  };

  const [aditionalDelivery, setAditionalDelivery] = useState(0);
  const [uploadError, setUploadError] = useState([]);
  const [newNote, setNewNote] = useState("");
  const [loadingNote, setLoadingNote] = useState(false);
  const [saved, setSaved] = useState(false);
  const [showSuccess, setShowSuccess] = useState({
    opacity: "0",
  });
  const sendNote = async (e) => {
    e.preventDefault();
    setLoadingNote(true);
    try {
      const resp = await fetch(`${URL}/admin/shipments/send_note/${id}`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token()}`,
        },
        body: JSON.stringify({ notes: newNote }),
      });
      const data = await resp.json();
      if (resp.status == 200) {
        setShowSuccess({
          opacity: "1",
        });
        setSaved(true);
        setTimeout(() => {
          setShowSuccess({
            opacity: "0",
          });
          setSaved(false);
        }, 5000);
        setLoadingNote(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const [newFileType, setNewFileType] = useState("");

  const submitFileType = async () => {
    if (newFileType.length >= 2) {
      const obj = {
        types: [newFileType],
      };

      let aux = { ...shipment };

      loadingAnimation(true);

      try {
        const resp = await fetch(`${URL}/admin/shipments/${id}/add_doc_type`, {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token()}`,
          },
          body: JSON.stringify(obj),
        });
        const data = await resp.json();

        if (resp.status == 200) {
          const newType = {
            id: data?.type_set[newFileType]?.id,
            required: 1,
            shipment_id: data?.type_set[newFileType]?.shipment_id,
            submitted: 0,
            type: data?.type_set[newFileType]?.type,
          };

          loadingAnimation(false);
          aux.types.push(newType);
          setNewFileType("");
        } else {
          loadingAnimation(false);
          toast.error(
            "Something went wrong. Please, try again in a few minutes!"
          );
        }
      } catch (e) {
        loadingAnimation(false);
        toast.error(
          "Something went wrong. Please, try again in a few minutes!"
        );
      }

      setShipment(aux);
    }
  };

  const [newDocNote, setNewDocNote] = useState("");

  const handleDocNote = (e) => {
    setShipment({ ...shipment, document_note: e.target.value });
  };
  const clearDocNote = () => {
    setShipment({ ...shipment, document_note: "" });

    submitDocNote(true);
  };

  const submitDocNote = async (clear) => {
    if (clear == false) {
      setNewDocNote("loading");
      loadingAnimation(true);
      try {
        const resp = await fetch(`${URL}/admin/shipments/${id}/doc_note`, {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token()}`,
          },
          body: JSON.stringify({ note: shipment.document_note }),
        });

        if (resp.status == 200) {
          loadingAnimation(false);
          setNewDocNote("success");
        } else {
          loadingAnimation(false);
          toast.error(
            "Something went wrong. Please, try again in a few minutes!"
          );
        }
      } catch (e) {
        loadingAnimation(false);
        toast.error(
          "Something went wrong. Please, try again in a few minutes!"
        );
      }
    } else if (clear == true) {
      loadingAnimation(true);
      try {
        const resp = await fetch(`${URL}/admin/shipments/${id}/doc_note`, {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token()}`,
          },
          body: JSON.stringify({ note: null }),
        });
        const data = await resp.json();

        if (resp.status == 200) {
          loadingAnimation(false);
          setNewDocNote("success");
        } else {
          loadingAnimation(false);
          toast.error(
            "Something went wrong. Please, try again in a few minutes!"
          );
        }
      } catch (e) {
        loadingAnimation(false);
        toast.error(
          "Something went wrong. Please, try again in a few minutes!"
        );
      }
    }
  };

  const disapproveType = async (typeChecked) => {
    let aux = { ...shipment };

    try {
      const resp = await fetch(`${URL}/admin/shipments/${id}/unset_type`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token()}`,
        },
        body: JSON.stringify({
          types: typeChecked.type,
        }),
      });
      const data = await resp.json();

      if (resp.status == 200) {
        aux.types.forEach((type) => {
          if (type?.id == typeChecked?.id) {
            type.submitted = 0;
          }
        });
      } else {
        toast.error(
          "Something went wrong. Please, try again in a few minutes!"
        );
      }
    } catch (e) {
      console.log(e);
      toast.error("Something went wrong. Please, try again in a few minutes!");
    }

    setShipment(aux);
  };

  const approveType = async (typeChecked) => {
    let aux = { ...shipment };
    let x = 0;

    try {
      const resp = await fetch(`${URL}/admin/shipments/${id}/set_type`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token()}`,
        },
        body: JSON.stringify({ types: typeChecked?.type }),
      });
      const data = await resp.json();

      if (resp.status == 200) {
        if (data?.message?.includes("already set")) {
          toast.error("The type has already been set!");
        } else {
          aux.types.forEach((type) => {
            if (type?.id == typeChecked?.id) {
              type.submitted = 1;
            }

            if (type?.submitted == 1) {
              x++;
            }
          });
        }
      } else {
        toast.error(
          "Something went wrong. Please, try again in a few minutes!"
        );
      }
    } catch (e) {
      console.log(e);
      toast.error("Something went wrong. Please, try again in a few minutes!");
    }

    setShipment(aux);
  };

  const addExam = async (exam) => {
    let fda_status = exam.value;
    if (exam.active) {
      fda_status = null;
    }

    try {
      let url = `${URL}/admin/shipments/${id}/update_fda_status`;
      const resp = await fetch(url, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token()}`,
        },
        body: JSON.stringify({
          review_type: fda_status,
        }),
      });
      const data = await resp.json();
      if (resp.status == 200) {
        if (data.shipment.fda_review) {
          let exams = availableExams;
          exams.map((e) => {
            if (e.value == data.shipment.fda_review) {
              e.active = true;
            } else {
              e.active = false;
            }
            return e;
          });
          setAvailableExams(exams);
          setShipment({ ...shipment, exams: [exams] });
        } else {
          let exams = availableExams;
          exams.map((e) => {
            e.active = false;
            return e;
          });
          setAvailableExams(exams);
          setShipment({ ...shipment, exams: [exams] });
        }
      } else {
        toast.error(
          "Something went wrong. Please, try again in a few minutes!"
        );
      }
    } catch (e) {
      console.log(e);
      toast.error("Something went wrong. Please, try again in a few minutes!");
    }
  };

  const bolRequired = async (e) => {
    const types = shipment.types;
    const bol = types.filter((i) => i.type == "Bill of Lading");
    let isRequired = bol[0]?.required;

    try {
      const resp = await fetch(
        `${URL}/admin/shipments/${id}/require_doc_type/${bol[0].id}`,
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token()}`,
          },
          body: JSON.stringify({
            required: !isRequired,
          }),
        }
      );
      const data = await resp.json();
      if (resp.status == 200) {
        types.map((i) => {
          if (i.type == "Bill of Lading") {
            return (i.required = !isRequired);
          }
          return i;
        });
        console.log(types);
        setShipment({ ...shipment, types: types });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleNoteByUser = () => {
    const filteredShipments = shipment.shipment_notes.filter((ship) => {
      return ship.role === 3 && ship.deleted_at === null;
    });

    if (filteredShipments[filteredShipments.length - 1]) {
      let returned = filteredShipments[filteredShipments.length - 1].comments;
      return returned;
    }
    return;
  };

  const [showNoteByUser, setShowNoteByUser] = useState(false);

  /**
   * Shipment object state checking
   * after data is correctly fetched
   */
  useEffect(() => {
    if (!loading && !(typeof shipment === "undefined")) {
      function checkTypes() {
        let typesAux = [];

        shipment.types.forEach((t) => {
          typesAux.push({
            type: t.type,
            approved: 0,
          });
        });

        setTypeApproval(typesAux);
      }

      /* Check if user has note */
      /* Client role == 3 */

      function checkUserNotes() {
        const filteredShipments = shipment.shipment_notes.filter((ship) => {
          return ship.role === 3 && ship.deleted_at === null;
        });

        if (filteredShipments.length == 0) {
          setShowNoteByUser(false);
        } else {
          setShowNoteByUser(true);
        }
      }

      checkUserNotes();
      checkTypes();
    }
  }, [shipment]);

  const toSelectValue = (value) => {
    var label = value
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    label = label.replace("Cbp", "CBP").replace("Isf", "ISF");
    if (value == "SUBMITTED") {
      label = "Awaiting Arrival";
    }
    if (value == "PENDING DOCUMENTS") {
      label = "Documents Required";
    }
    return { label: label, value: value };
  };

  /* Delivery Order Errors */
  const getDoError = (id) => {
    if (doError.filter((e) => e.id === id).length > 0)
      return doError.filter((e) => e.id === id)[0].message;
    return false;
  };

  // New Supplementary File Upload variables and methods

  const [supplementaryFileArray, setSupplementaryFileArray] = useState([]);

  const supplementaryFileUploadStatus = (status, item) => {
    if (status === "success" && item) {
      setSupplementaryFileArray((oldArray) => [...oldArray, item]);
    }
  };
  const deleteDocType = async (type) => {
    let url = `${process.env.REACT_APP_API_URL}/admin/shipments/${type?.shipment_id}/delete/${type?.id}`;
    let aux = { ...shipment };

    const type_remove_index = aux?.types?.findIndex((el) => el.id == type?.id);

    loadingAnimation(true);

    try {
      fetch(url, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token()}`,
        },
      })
        .then((resp) => {
          if (resp?.status == 200) {
            aux?.types?.splice(type_remove_index, 1);

            loadingAnimation(false);
            setShipment(aux);
          } else {
            loadingAnimation(false);
            throw new Error(
              "Could not delete document type, try again in a few minutes!"
            );
          }
        })
        .catch((e) => {
          toast.error(e?.message);
        });
    } catch (e) {
      loadingAnimation(false);
      console.log(e?.message);
    }
  };

  const deleteSupplementaryFile = (file_path) => {
    const URL = `${process.env.REACT_APP_API_URL}/admin/shipments/${id}/delete_doc`;

    let filePath = JSON.stringify({ path: file_path });

    fetch(URL, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token()}`,
      },
      body: filePath,
    })
      .then((response) => response.json())
      .then((data) => {
      })
      .catch((error) => {
        console.error("Upload error:", error);
        toast.error(
          "Something went wrong. Please, try again in a few minutes!"
        );
      });

    setSupplementaryFileArray(
      supplementaryFileArray.filter((item) => item?.path !== file_path)
    );
  };

  const DropZone = () => {
    const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
      let errors = [];
      if (rejectedFiles.length > 0) {
        errors = rejectedFiles.map(
          (file) =>
            "Couldn't upload " +
            file.file.path +
            " - " +
            file.errors[0].message
        );
      }

      const re = /(?:\.([^.]+))?$/;
      acceptedFiles.forEach(async (file) => {

        const path_name = `changed-${file.path}`;

        Object.defineProperty(file, "name", {
          writable: true,
          value: path_name,
        });

        const ext = re.exec(file.path)[1];

        if (
          ext === "pdf" ||
          ext === "PDF" ||
          ext === "xls" ||
          ext === "xlsx" ||
          ext === "png" ||
          ext === "docx" ||
          ext === "doc"
        ) {
          const formData = new FormData();
          formData.append("file", file);

          try {
            loadingAnimation(true);
            const resp = await fetch(`${URL}/admin/shipments/main/upload_doc/${id}`, {
              method: "POST",
              headers: {
                Authorization: `Bearer ${token()}`,
              },
              body: formData,
            });
            const data = await resp.json();
            if (resp.status === 200) {
              const updateShipment = { ...shipment };
              updateShipment.documents.push({
                shipment_id: shipment.id,
                document_type: "",
                path: data?.path_to_file,
              });
              setShipment({ ...shipment, documents: updateShipment.documents });
              loadingAnimation(false);
            } else {
              console.log("Upload failed, status:", resp.status);
              loadingAnimation(false);
              toast.error(
                "Something went wrong. Please, try again in a few minutes!"
              );
            }
          } catch (error) {
            console.error("Error during upload:", error);
            loadingAnimation(false);
            toast.error(
              "Something went wrong. Please, try again in a few minutes!"
            );
          }
        } else {
          errors.push(
            "Couldn't upload " +
            file.path +
            " - " +
            "File type should be pdf, excel or png"
          );
          console.log("Unsupported file type:", file.path);
        }
      });

      setUploadError(errors);
      console.log("Final upload errors:", errors);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
      accept: {
        'application/pdf': ['.pdf'],
        'application/vnd.ms-excel': ['.xls', '.xlsx'],
        'image/png': ['.png'],
        'application/msword': ['.doc', '.docx'],
      },
    });

    return (
      <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}>
        <input {...getInputProps()} />
        {isDragActive ? <p>Drop the files here ...</p> : <p>Drag 'n' drop some files here, or click to select files</p>}
      </div>
    );
  };

  // Add this to ShipmentInfo component
  const [refreshKey, setRefreshKey] = useState(0);

  const triggerRefresh = () => {
    setRefreshKey((prevKey) => prevKey + 1);
  };

  // Fetch shipment data whenever refreshKey changes
  useEffect(() => {
    getShipmentData();
  }, [refreshKey]);

  /* Delivery Orders */
  const deliveryOrders = (params) => {
    const { numberOfDeliveries } = params;

    var deliveries = shipment?.delivery_orders
      ? shipment?.delivery_orders?.length
      : 0;
    var num = deliveries + aditionalDelivery;

    const handleNewDelete = (i) => {
      setAditionalDelivery(aditionalDelivery - 1);
    };
    const handleDelete = (i) => {
      delete deliveries[i];
      getShipmentData();
    };

    return (
      <div>
        {deliveries + aditionalDelivery < 1 ? (
          <p className="empty">
            Create your first delivery order by clicking New Delivery Order
            above
          </p>
        ) : (
          <>
            {[...Array(deliveries + aditionalDelivery)].map((_, i) => (
              <div key={i}>
                <DeliveryOrder
                  x={i}
                  id={id}
                  handleDelete={() => handleDelete(i)}
                  handleNewDelete={() => handleNewDelete(i)}
                  triggerRefresh={triggerRefresh}
                />
              </div>
            ))}
          </>
        )}
      </div>
    );
  };

  const toggleIgnoreShipment = async (shipmentId, ignore) => {
    try {
      const response = await fetch(`${URL}/admin/shipments/toggle-ignore`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ shipment_id: shipmentId, ignore }),
      });

      if (response.status === 200) {
        toast.success("Shipment ignore status updated successfully!");
        getShipmentData(); // Refresh the shipment data after toggling ignore
      } else {
        const errorData = await response.json();
        toast.error(
          errorData.error || "Something went wrong, please try again later."
        );
      }
    } catch (error) {
      toast.error("Unable to update shipment ignore status, please try again later.");
      console.error(error);
    }
  };

  const [isEtaManuallyEdited, setIsEtaManuallyEdited] = useState(false);

  useEffect(() => {
    if (shipment) {
      setIsEtaManuallyEdited(shipment.eta_manually_edited);
    }
  }, [shipment]);


  const userRole = cookies.get('role');
  const shouldRender = userRole !== '7';


  const updateEta = async (date) => {
    try {
      const resp = await fetch(`${URL}/shipments/eta/${shipment.id}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token()}`,
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          est_time_arrive: date,
        }),
      });

      if (resp.status === 200) {
        const data = await resp.json();
        setShipment(prevShipment => ({ ...prevShipment, est_time_arrive: date }));
        toast.success("ETA updated successfully!");
      } else {
        console.error(`HTTP error: ${resp.status}`);
        toast.error(
          `Error: ${resp.status}. Could not update ETA. Please try again in a few minutes!`
        );
      }
    } catch (err) {
      console.error(`Fetch error: ${err}`);
      toast.error(`Could not complete request. Please try again in a few minutes!`);
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    const formattedDate = format(date, 'yyyy-MM-dd'); // Format to YYYY-MM-DD
    updateEta(formattedDate);
  };

  const updateEtaManuallyEditedStatus = async (checked) => {
    try {
      const response = await fetch(`${URL}/shipments/eta/${shipment.id}/false`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          eta_manually_edited: checked,
        }),
      });

      if (response.status === 200) {
        const data = await response.json();
        toast.success("ETA manually edited status updated successfully!");
      } else {
        console.error(`HTTP error: ${response.status}`);
        toast.error(`Error ${response.status}: Could not update status. Please try again.`);
      }
    } catch (error) {
      console.error(`Fetch error: ${error}`);
      toast.error("Could not complete request. Please try again.");
    }
  };

  const handleCheckboxChange = (e) => {
    const checked = e.target.checked;
    setIsEtaManuallyEdited(checked);
    updateEtaManuallyEditedStatus(checked);
  };

  return (
    <>
      {loading || typeof shipment === "undefined" ? (
        <>
          <LoadingTitle />
          <div className="page-content loading"></div>
        </>
      ) : (
        <>
          <Title
            title={`Shipment #${shipment.file_number}`}
            links={[
              { link: "/", title: "Home" },
              { link: "/shipments", title: "Shipments" },
              {
                link: `/shipments/edit/${shipment.id}`,
                title: `Shipment #${shipment.file_number}`,
              },
            ]}
          />
          <br />
          {shouldShowButtons && (
            <div className="navigation-arrows">
              <button
                className="arrow-btn"
                onClick={() => handleNavigation('prev')}
                disabled={loading || filteredShipmentIds.length <= 1}
                title="Previous shipment"
              >
                <img src={BackwardArrow} alt="Backward Arrow" className="arrow-icon" />
                <span className="arrow-text">Previous Shipment</span>
              </button>

              <button
                className="arrow-btn"
                onClick={() => handleNavigation('next')}
                disabled={loading || filteredShipmentIds.length <= 1}
                title="Next shipment"
              >
                <span className="arrow-text">Next Shipment</span>
                <img src={ForwardArrow} alt="Forward Arrow" className="arrow-icon" />
              </button>
            </div>
          )}
          <br />
          <div id="grey-line"></div>
          <div className="page-content">
            <div id="shipment-info">
              <div className="left-side">
                <div className="form-process">
                  {shouldRender ? (
                    <div className="form-step" id="step-1">
                      <div
                        className={`circle-completed ${opened1 && "isOpen"}`}
                        style={
                          shipment.isf_transaction_no
                            ? greenAndBoldCircle
                            : opened1
                              ? {
                                backgroundColor: "var(--green)",
                              }
                              : {
                                border: "2px solid var(--border-color)",
                                backgroundColor: "white",
                              }
                        }
                      >
                        {/*Progress circle */}
                        {shipment.isf_transaction_no ? (
                          <span style={greenAndBoldText}>✓</span>
                        ) : (
                          <span
                            style={
                              opened1
                                ? {
                                  color: "white",
                                }
                                : {
                                  color: "black",
                                  fontWeight: "lighter",
                                }
                            }
                          >
                            1
                          </span>
                        )}
                      </div>
                      <div className="form-body">
                        <p className="form-title" onClick={toggleMenu1}>
                          <span
                            style={
                              step1
                                ? {
                                  color: "var(--green)",
                                  fontWeight: "600",
                                }
                                : !opened1
                                  ? {
                                    color: "black",
                                  }
                                  : {
                                    color: "var(--green)",
                                    fontWeight: "600",
                                  }
                            }
                          >
                            ISF Submitted
                          </span>
                          <span className="arrow">
                            {opened1 ? <ArrowUp /> : <ArrowDown />}
                          </span>
                        </p>
                        <div className="form-content" style={contentDisplay1}>
                          {shipment.isf_transaction_no != null &&
                            shipment.isf_transaction_no != "" &&
                            shipment.isf_submit_date != null ? (
                            shipment.isf_transaction_no === "N/A" ? (
                              <p>ISF Submitted by user</p>
                            ) : (
                              <p>
                                Date filed:
                                {checkDateValidity(shipment?.isf_submit_date) ===
                                  true
                                  ? format(
                                    new Date(shipment?.isf_submit_date),
                                    "MM/dd/yyyy"
                                  )
                                  : "NOT AVAILABLE"}
                              </p>
                            )
                          ) : (
                            <p>No ISF Number</p>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <p></p> // Optional: message for role 7
                  )}
                  <div className="form-step" id="step-2">
                    <div
                      className="circle-completed"
                      style={
                        shipment.status == "RELEASED" ||
                          shipment.status == "AWAITING ARRIVAL" ||
                          shipment.status == "UNDER CBP REVIEW"
                          ? greenAndBoldCircle
                          : !opened2
                            ? {
                              border: "2px solid #CCC7C7",
                              background: "white",
                            }
                            : {
                              border: "none",
                              background: "var(--green)",
                              color: "white",
                            }
                      }
                    >
                      {shipment.status == "RELEASED" ||
                        shipment.status == "AWAITING ARRIVAL" ||
                        shipment.status == "UNDER CBP REVIEW" ? (
                        <span style={{ color: "var(--green)" }}>
                          <AiOutlineCheck />
                        </span>
                      ) : (
                        <span>{userRole === '7' ? '1' : '2'}</span>
                      )}
                    </div>
                    <div className="form-body">
                      <p className="form-title" onClick={toggleMenu2}>
                        <span
                          style={
                            step2
                              ? {
                                color: "var(--green)",
                                fontWeight: "600",
                              }
                              : !opened2
                                ? {
                                  color: "black",
                                }
                                : {
                                  color: "var(--green)",
                                  fontWeight: "600",
                                }
                          }
                        >
                          Download Uploaded Documents
                        </span>
                        <span className="arrow">
                          {opened2 ? <ArrowUp /> : <ArrowDown />}
                        </span>
                      </p>
                      {step2 ? (
                        <div className="form-content" style={contentDisplay2}>
                          <p>Documents uploaded!</p>
                        </div>
                      ) : (
                        <div className="form-content" style={contentDisplay2}>
                          <div className="docs_download">
                            <p className="step-2_title">Download user uploaded documents</p>
                            <div className="all-docs">
                              {shipment.documents.length ? (
                                sortDocuments(shipment.documents).map((doc, i) => {
                                  // Skip 7501 documents
                                  if (doc.document_type === "7501") {
                                    return null;
                                  }

                                  // Check should_view_document for role 7
                                  if (userRole === '7' && !doc.should_view_document) {
                                    return null; // Don't render if the user doesn't have permission
                                  }

                                  return (
                                     <div className="doc-container left" key={i}>
                                      <a
                                        href={`${process.env.REACT_APP_BASE_URL}/${doc.path.replace("public", "")}`}
                                        target="_blank"
                                        download={`${getFileName(doc.path)}`}
                                      >
                                        <div className="icon-container">
                                          <div className={`icon ${doc.deleted_at == null ? "green" : ""}`}>
                                            <HiDocumentDownload />
                                          </div>
                                        </div>
                                      </a>
                                      <div className="document-info">
                                        <p className="file-name">
                                          <a
                                            href={`${process.env.REACT_APP_BASE_URL}/${doc.path.replace("public", "")}`}
                                            target="_blank"
                                            download={`${getFileName(doc.path)}`}
                                          >
                                            {getFileName(doc.path)}
                                          </a>
                                        </p>
                                        <p className="file-date">
                                          Uploaded:{" "}
                                          {checkDateValidity(doc.created_at) === true
                                            ? format(new Date(doc?.created_at), "MMMM dd, hh:mm aaa")
                                            : "NOT AVAILABLE"}
                                          {doc.deleted_at != null && (
                                            <>
                                              <br />
                                              Deleted at:{" "}
                                              {checkDateValidity(doc.deleted_at) === true
                                                ? format(new Date(doc?.deleted_at), "MMMM dd, hh:mm aaa")
                                                : "NOT AVAILABLE"}
                                            </>
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  );
                                })
                              ) : (
                                <div className="no-docs-message">No documents submitted yet</div>
                              )}
                            </div>
                          </div>
                          <DropZone />
                          <div className="approve-docs">
                            {shipment.types.length > 0 && (
                              <p className="step-2_title">
                                Mark documents as approved
                              </p>
                            )}

                            <div className="check-list">
                              {shipment.types.map((type, i) => {
                                return (
                                  <div className="check-box" key={i}>
                                    <div
                                      className="box"
                                      onClick={() => {
                                        type.submitted == 1
                                          ? disapproveType(type)
                                          : approveType(type);
                                      }}
                                    >
                                      {type.submitted == 1 ? (
                                        <span className="approved-box">✓</span>
                                      ) : (
                                        <p></p>
                                      )}
                                    </div>
                                    <p className="box-text">{type.type}</p>
                                    <p
                                      className="box-dismiss"
                                      onClick={() => {
                                        deleteDocType(type);
                                      }}
                                    >
                                      <IoClose />
                                    </p>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                          {shouldRender ? (
                            <div className="require-docs">
                              <p className="step-2_title">
                                Request Additional Documents
                              </p>
                              <div className="req-input file-description">
                                <div className="input">
                                  <input
                                    type="text"
                                    placeholder="File Description"
                                    value={newFileType}
                                    onChange={(e) => {
                                      setNewFileType(e.target.value);
                                    }}
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter") {
                                        submitFileType();
                                      }
                                    }}
                                  />
                                </div>
                                <div className="button">
                                  <button
                                    onClick={() => {
                                      submitFileType();
                                    }}
                                  >
                                    Submit
                                  </button>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <p></p> // Optional: message for role 7
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  {shouldRender ? (
                    <div className="form-step" id="step-3">
                      <div
                        className="circle-completed"
                        style={
                          step3
                            ? {
                              border: "2px solid var(--green)",
                              background: "var(--global-green)",
                            }
                            : !opened3
                              ? {
                                border: "2px solid #CCC7C7",
                                background: "white",
                              }
                              : {
                                border: "none",
                                background: "var(--green)",
                                color: "white",
                              }
                        }
                      >
                        {step3 ? (
                          <span style={{ color: "var(--green)" }}>
                            <AiOutlineCheck />
                          </span>
                        ) : (
                          <span>{userRole === '7' ? '2' : '3'}</span>
                        )}
                      </div>
                      <div className="form-body">
                        <p className="form-title" onClick={toggleMenu3}>
                          <span
                            style={
                              step3
                                ? {
                                  color: "var(--green)",
                                  fontWeight: "600",
                                }
                                : !opened3
                                  ? {
                                    color: "black",
                                  }
                                  : {
                                    color: "var(--green)",
                                    fontWeight: "600",
                                  }
                            }
                          >
                            Shipment Released
                          </span>
                          <span className="arrow">
                            {opened3 ? <ArrowUp /> : <ArrowDown />}
                          </span>
                        </p>
                        <div className="form-content" style={contentDisplay3}>
                          <div className="exams">
                            {shipment.status == "RELEASED" &&
                              shipment.shipment_discharge_date && (
                                <p>
                                  Released on:{" "}
                                  {checkDateValidity(
                                    shipment?.shipment_discharge_date
                                  ) === true
                                    ? format(
                                      new Date(
                                        shipment?.shipment_discharge_date
                                      ),
                                      "MM/dd/yyyy"
                                    )
                                    : "NOT AVAILABLE"}
                                </p>
                              )}
                            <p className="exams-title">
                              Set FDA status manually. This will prevent the FDA
                              status from updating automatically.
                            </p>
                            <div className="check-list">
                              {availableExams.map((exam, i) => {
                                return (
                                  <div className="check-box" key={i}>
                                    <div
                                      className="box"
                                      onClick={() => addExam(exam)}
                                    >
                                      {exam.active == false ? (
                                        <p></p>
                                      ) : (
                                        <p className="checked-item">✓</p>
                                      )}
                                    </div>
                                    <p className="box-text">{exam.exam}</p>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <p></p> // Optional: message for role 7
                  )}
                </div>
                {shouldRender ? (
                  <div
                    className="update-order"
                    style={{
                      paddingBottom: "16px",
                    }}
                  >
                    <div className="update-header">
                      <div className="ignore-shipment-toggle">
                        <label>
                          <input
                            type="checkbox"
                            checked={shipment?.ignored_shipment} // Use ignored_shipment for checked state
                            onChange={(e) => toggleIgnoreShipment(shipment.id, e.target.checked)} // Handle toggle
                          />
                          <span className="checkbox">
                            <span>
                              <svg
                                width="15.668"
                                height="15.51"
                                viewBox="0 0 15.668 15.51"
                              >
                                <g transform="matrix(0.995, 0.105, -0.105, 0.995, -120.772, -167.938)">
                                  <line
                                    x2="5"
                                    y2="5"
                                    transform="translate(140.5 161.5)"
                                    fill="none"
                                    stroke="#f6fffb"
                                    strokeLinecap="round"
                                    strokeWidth="2"
                                  />
                                  <line
                                    x1="6"
                                    y2="12"
                                    transform="translate(146 155)"
                                    fill="none"
                                    stroke="#f6fffb"
                                    strokeLinecap="round"
                                    strokeWidth="2"
                                  />
                                </g>
                              </svg>
                            </span>
                          </span>
                          <span className="checkbox-label">
                            Skip Delivery Order
                          </span>
                        </label>
                      </div>

                      <button
                        href="#"
                        onClick={() => {
                          setAditionalDelivery(aditionalDelivery + 1);
                        }}
                      >
                        New Delivery Order
                      </button>
                    </div>
                  </div>
                ) : (
                  <p></p>
                )}
                <div>
                  {shouldRender ? (
                    deliveryOrders({
                      numberOfDeliveries: shipment.delivery_orders,
                    })
                  ) : (
                    <p></p>
                  )}
                </div>

                {/* Shipment 44 does not have user notes  */}
                {shipment.comment ? (
                  <div className="notes">
                    <p className="title">Comments</p>
                    <p className="light-subtitle">
                      Important notes left by the user.
                    </p>
                    <div
                      className="note by-user"
                      style={
                        shipment.comment
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      {shipment.comment}
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="right-side">
                <div className="shipment-details">
                  <p className="title">Shipment Details</p>

                  <div className="info-box" id="info-box-1">
                    <div className="info-box-type">
                      <p>Customer</p>
                    </div>
                    <div className="info-box-value">{shipment.account_no}</div>
                  </div>
                  <div className="info-box" id="info-box-1">
                    <div className="info-box-type">
                      <p>Container Number(s)</p>
                    </div>
                    <div className="info-box-value">
                      {containerNumbers(shipment.containers)}
                    </div>
                  </div>
                  {shouldRender ? (
                    <div className="info-box" id="info-box-2">
                      <div className="info-box-type">Reference Number</div>
                      <div className="info-box-value ref_no">
                        {shipment.customer_reference_no}
                      </div>
                    </div>
                  ) : (
                    <p></p>
                  )}
                  {shouldRender ? (
                    <div className="info-box" id="info-box-3">
                      <div className="info-box-type">ISF Number</div>
                      <div className="info-box-value">
                        {shipment.isf_transaction_no}
                      </div>
                    </div>
                  ) : (
                    <p></p>
                  )}
                  {shipment.master_bill_lading && (
                    <div className="info-box" id="info-box-4">
                      <div className="info-box-type">Master Bill of Lading</div>
                      <div className="info-box-value">
                        {shipment.master_bill_lading}
                      </div>
                    </div>
                  )}

                  {shipment.house_bill_lading && (
                    <div className="info-box" id="info-box-5">
                      <div className="info-box-type">House Bill of Lading</div>
                      <div className="info-box-value">
                        {shipment.house_bill_lading}
                      </div>
                    </div>
                  )}

                  {shouldRender ? (
                    <>
                      <div className="info-box" id="info-box-6">
                        <div className="info-box-type">Origin</div>
                        <div className="info-box-value">{shipment.origin}</div>
                      </div>

                      <div className="info-box" id="info-box-7">
                        <div className="info-box-type">Destination</div>
                        <div className="info-box-value">
                          {shipment.unlading_code.name}
                        </div>
                      </div>

                      <div className="info-box" id="info-box-8">
                        <div className="info-box-type">Destination Terminal</div>
                        <div className="info-box-value">
                          {shipment.destination_terminal}
                        </div>
                      </div>

                      <div className="info-box" id="info-box-9">
                        <div className="info-box-type">Steamship Line</div>
                        <div className="info-box-value">
                          {shipment.steamship_line}
                        </div>
                      </div>

                      <div className="info-box" id="info-box-10">
                        <div className="info-box-type">Vessel</div>
                        <div className="info-box-value">{shipment.vessel_name}</div>
                      </div>

                      <div className="info-box" id="info-box-11">
                        <div className="info-box-type">Mode of Transport</div>
                        <div className="info-box-value">
                          {shipment.transport_mode.description.replace("vessele:", "")}
                        </div>
                      </div>
                    </>
                  ) : (
                    <p></p> // Optional: message for role 7
                  )}
                  <div className="info-box" id="info-box-12">
                    <div className="info-box-type">ETA</div>
                    <div className="info-box-value">
                      {userRole === '7' ? (
                        <DatePicker
                          selected={selectedDate || (shipment?.est_time_arrive ? new Date(shipment.est_time_arrive) : null)}
                          onChange={handleDateChange}
                          dateFormat="MM/dd/yyyy"
                          placeholderText={selectedDate ? format(selectedDate, "MM/dd/yyyy") : "Select a date"}
                        />
                      ) : (
                        <>
                          {shipment?.est_time_arrive && checkDateValidity(shipment.est_time_arrive)
                            ? format(new Date(shipment.est_time_arrive), "MM/dd/yyyy")
                            : "NOT AVAILABLE"}

                          {/* Conditionally render the checkbox if eta_manually_edited is true */}
                          {shipment?.eta_manually_edited && (
                            <label style={{ marginLeft: "10px" }}>
                              <input
                                type="checkbox"
                                checked={isEtaManuallyEdited}
                                onChange={handleCheckboxChange}
                              />
                              Remove from ETA box
                            </label>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                {shouldRender ? (
                  <div className="set_status" style={{ maxWidth: "250px" }}>
                    <p style={{ marginBottom: "12px" }}>
                      Set shipment status manually
                    </p>
                    <Select
                      styles={customSelectStyles}
                      onChange={(e) => {
                        changeStatus(e.value);
                      }}
                      options={[
                        { label: "Awaiting Arrival", value: "SUBMITTED" },
                        { label: "Documents Required", value: "PENDING DOCUMENTS" },
                        { label: "Pending Release", value: "PENDING RELEASE" },
                        { label: "Released", value: "RELEASED" },
                        { label: "CBP Intensive Exam", value: "CBP INTENSIVE EXAM" },
                        { label: "CBP VACIS Exam", value: "CBP VACIS EXAM" },
                        { label: "CBP Docs Review Hold", value: "CBP DOCS REVIEW HOLD" },
                        { label: "Not Cleared by AFB", value: "NOT CLEARED BY AFB" },
                        { label: "ISF Hold", value: "ISF HOLD" },
                      ]}
                      className="custom-select"
                      defaultValue={toSelectValue(shipment.status)}
                    />
                  </div>
                ) : (
                  <p></p> // Optional: message for role 7
                )}
                <br />
                {shouldRender ? (
                  <ToggleSwitch
                    label="Display on Homepage"
                    value={shipment.show_on_home}
                    setValue={removeFromHomePage}
                  />
                ) : (
                  <p></p> // Optional: message for role 7
                )}
                {shouldRender ? (
                  <div key={refreshKey} className="files">
                    <a
                      target="_blank"
                      className={`file ${shipment?.path_to_7501 ? "active-link" : "no-link"}`}
                      id="file-1"
                      href={
                        shipment?.path_to_7501
                          ? `${process.env.REACT_APP_BASE_URL + "/" + shipment?.path_to_7501}`
                          : `#`
                      }
                    >
                      <FileIcon />
                      <p>7501 Form</p>
                    </a>

                    {shipment?.invoices && shipment.invoices.length > 0 ? (
                      shipment.invoices.map((invoice, index) => (
                        <a
                          key={index}
                          target="_blank"
                          className="file active-link"
                          id={`file-invoice-${index}`}
                          href={`${process.env.REACT_APP_BASE_URL +
                            "/" +
                            invoice.path.replace(
                              "/var/www/html/atlantic-brokers/storage/app/public/",
                              ""
                            )
                            }`}
                          download={`invoice-${index + 1}.pdf`}
                        >
                          <FileIcon />
                          <p>Billing Invoice {index + 1}</p>
                        </a>
                      ))
                    ) : (
                      <a className="file no-link" id="file-no-invoice" href="#">
                        <FileIcon />
                        <p>Billing Invoice</p>
                      </a>
                    )}

                    {shipment.delivery_orders && shipment.delivery_orders.length > 0 ? (
                      shipment.delivery_orders
                        .filter((order) => order?.path?.length > 0)
                        .map((order, orderIndex) => (
                          <a
                            key={orderIndex}
                            href={process.env.REACT_APP_BASE_URL + "/" + order.path}
                            target="_blank"
                            className="file active-link"
                            id={`file-order-${orderIndex}`}
                          >
                            <div className="icon green">
                              <FileIcon />
                            </div>
                            <p>Delivery Order {orderIndex + 1}</p>
                          </a>
                        ))
                    ) : (
                      <a href="#" className="file no-link" id="file-no-delivery-order">
                        <FileIcon />
                        <p>Delivery Order</p>
                      </a>
                    )}
                  </div>
                ) : (
                  <p></p> // Optional: message for role 7
                )}
                {shouldRender ? (
                  <div className="set_status" style={{ maxWidth: "250px" }}>
                    <p style={{ marginBottom: "12px" }}>
                      Upload Additional Files
                    </p>
                    <Uploader
                      shipmentId={id}
                      uploadStatus={supplementaryFileUploadStatus}
                    />
                  </div>
                ) : (
                  <p></p> // Optional: message for role 7
                )}
                {supplementaryFileArray?.length > 0 ? (
                  <section className={"supplementary-file-section"}>
                    <p className={"supplementary-files-title"}>
                      Additional Files
                    </p>
                    <div className="supplementary-files-container files">
                      {supplementaryFileArray.map((item, index) => {
                        const fileName = item?.path.slice(
                          item?.path.lastIndexOf("/") + 1
                        );
                        return (
                          <a
                            target={"_blank"}
                            className={"file active-link file-card"}
                            id={`file-${index + 4}`}
                            href={process.env.REACT_APP_BASE_URL + item?.path}
                            download={fileName}
                            key={index}
                          >
                            <span
                              className="supplementary-x-container"
                              onClick={(e) => {
                                e.preventDefault();
                                deleteSupplementaryFile(item?.path);
                              }}
                            >
                              <svg
                                className="supplementary-x"
                                width="11.72"
                                height="11.719"
                                viewBox="0 0 11.72 11.719"
                              >
                                <path
                                  d="M10.3,9.355l4.218-4.211a.672.672,0,1,0-.951-.951L9.354,8.411,5.143,4.193a.672.672,0,0,0-.951.951L8.41,9.355,4.192,13.566a.672.672,0,1,0,.951.951L9.354,10.3l4.211,4.218a.672.672,0,1,0,.951-.951Z"
                                  transform="translate(-3.494 -3.496)"
                                  fill="#fff"
                                  stroke="#fff"
                                  strokeWidth="1"
                                />
                              </svg>
                            </span>

                            <FileIcon />
                            <p className="file-card-name">{fileName}</p>
                          </a>
                        );
                      })}
                    </div>
                  </section>
                ) : (
                  <section></section>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
