import { Title } from "../../components/Title.jsx";

//---------------------------STYLES------------------------------
import "../../App.scss";
import "../../stylesheets/Home.scss";

const ComingSoon = () => {
  return (
    <>
      <Title title="Coming Soon" />
      <div id="coming-soon" className="page-content">
        <h2>Page Coming Soon</h2>
      </div>
    </>
  );
};
export default ComingSoon;
