import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Autocomplete from "react-autocomplete";
import { useParams } from "react-router-dom";
import {
  FaUser,
  FaEnvelope,
  FaLock,
  FaTimes,
  FaAngleDown,
  FaAngleUp,
  FaEye,
  FaEyeSlash,
} from "react-icons/fa";
import Select from "react-select";

import Sidebar from "../../components/Sidebar";
import { Title, LoadingTitle } from "../../components/Title";
import { DeleteUser } from "./DeleteUser";
import {
  URL,
  token,
  LoadingIcon,
  formSelectStyles,
  validateEmail,
  SuccessMessage,
  LoadingTable,
  role,
} from "../../components/Utils";

import "../../stylesheets/UserInfo.scss";
import { toast } from "react-toastify";
import { loadingAnimation } from "../../components/scripts/functions";

export const NewUser = () => {
  const { id } = useParams();

  const [selectedClients, setSelectedClients] = useState([]);
  const [user, setUser] = useState({
    email: "",
    name: "",
    role: "",
    password: "",
    clients: [],
  });
  const [availableClients, setAvailableClients] = useState([]);
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [allClients, setAllClients] = useState();
  const [loadingClients, setLoadingClients] = useState(false);
  const [searchedClient, setSearchedClient] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    let aux = { ...user };
    const obj = {};

    for (let i = 0; i < user?.clients?.length; i++) {
      obj[i] = user.clients[i];
    }

    aux.client = obj;
    setUser(aux);
  }, [user?.clients?.length]);

  useEffect(() => {
    getAllClients();
    if (id) {
      getUser();
    }
  }, []);

  const getUser = async () => {
    setLoading(true);
    try {
      const resp = await fetch(`${URL}/admin/managers/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });
      const data = await resp.json();
      if (resp.status == 200) {
        let curUser = {
          email: data.manager.email,
          name: data.manager.name,
          role: data.manager.role,
          password: "",
          clients: data.associated_clients.map((i) => i.account_no),
        };
        setUser(curUser);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getAllClients = async () => {
    setLoadingClients(true);

    try {
      const resp = await fetch(`${URL}/admin/clients_all`, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token()}`,
        },
      });
      const data = await resp.json();
      if (resp.status === 200) {
        setAllClients(data.clients);
        setLoadingClients(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleDeleteClient = (name) => {
    let clients = user?.clients;
    let filtered = clients.filter((i) => i != name);
    setUser({ ...user, clients: filtered });
  };

  const fetchedClients = (value) => {
    let rendered = allClients.filter((c) =>
      c.account_no.toLowerCase().includes(value.toLowerCase())
    );

    const addClient = (name) => {
      let clients = user?.clients;
      clients.push(name);
      setSelectedClients({ ...user, clients: clients });
      setSearchedClient("");
    };

    return (
      <>
        {value.length > 0 ? (
          <div className="fetched-clients">
            {rendered.map((r, i) => {
              return (
                <p
                  key={i}
                  onClick={() => {
                    addClient(r.account_no);
                  }}
                >
                  {r.account_no}
                </p>
              );
            })}
          </div>
        ) : (
          <></>
        )}
      </>
    );
  };

  const handleCreateUser = async (e) => {
    e.preventDefault();
    setLoading(true);

    let aux = { ...user };
    if (aux.role == 1) {
      aux.client = {};
    }
    setUser(aux);

    let curUrl = `${URL}/admin/register`;
    if (id) curUrl = `${URL}/admin/managers/${id}/update`;

    if (validateUser(aux)) {
      loadingAnimation(true);
      try {
        const resp = await fetch(curUrl, {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token()}`,
          },
          body: JSON.stringify(user),
        });
        const data = await resp.json();
        let curErrors = [];
        if (resp.status === 200) {
          if (!id) navigate(`/users`);
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
          }, 2000);
        } else if (
          data.errors.some((i) => i == "The email has already been taken.")
        ) {
          curErrors.push({
            field: "email",
            message: "The email is already in use.",
          });
        }
        setErrors(curErrors);
        loadingAnimation(false);
      } catch (e) {
        console.log(e);
        loadingAnimation(false);
      }
    } else {
      console.log("errors");
      loadingAnimation(false);
    }

    setLoading(false);
  };

  const validateUser = (user) => {
    let noErrors = true;
    let curErrors = [];
    if (!validateEmail(user.email)) {
      curErrors.push({
        field: "email",
        message: "Please enter a valid email address",
      });
      noErrors = false;
    }
    if (user.name.length < 1) {
      curErrors.push({ field: "name", message: "Please enter a user's name" });
      noErrors = false;
    }
    if (!user.role) {
      curErrors.push({
        field: "role",
        message: "Please select a user position",
      });
      noErrors = false;
    }
    setErrors(curErrors);
    return noErrors;
  };

  const hasError = (field) => {
    return errors.some((i) => i.field == field);
  };
  const getError = (field) => {
    return errors.filter((i) => i.field == field)[0];
  };
  const removeError = (field) => {
    setErrors(errors.filter((i) => i.field != field));
  };

  const getRole = (role) => {
    let curRole = null;
    switch (role) {
      case 1:
        curRole = { label: "Admin", value: 1 };
        break;
      case 2:
        curRole = { label: "Manager", value: 2 };
        break;
      case 6:
        curRole = { label: "Office Manager", value: 6 };
        break;
      case 7:
        curRole = { label: "AGF", value: 7 };
        break;
    }
    return curRole;
  };

  return (
    <>
      {loadingClients || typeof allClients === "undefined" ? (
        <>
          <LoadingTitle />
          <div id="create-user-page" className="page-content">
            <LoadingTable />
          </div>
        </>
      ) : (
        <>
          {id ? (
            <Title
              title={user.name}
              links={[
                { link: "/", title: "Home" },
                { link: "/users", title: "Users" },
                { link: "/users/edit/" + id, title: user.name },
              ]}
            />
          ) : (
            <Title
              title={`New User`}
              links={[
                { link: "/", title: "Home" },
                { link: "/users", title: "Users" },
                { link: "/users/edit", title: "New User" },
              ]}
            />
          )}
          <div id="create-user-page" className="page-content">
            <form onSubmit={handleCreateUser}>
              <div id="user-info">
                <div className="title section">
                  <h3>User Information</h3>
                </div>
                <div className="section">
                  <div className="section-wrapper">
                    <div className="left">
                      <p className="subtitle">Email Address</p>
                    </div>
                    <div className="right">
                      <div
                        className={`input ${hasError("email") ? "error" : ""}`}
                      >
                        <div className="input-icon">
                          <FaEnvelope />
                        </div>
                        <input
                          type="email"
                          placeholder="Email"
                          name="email"
                          value={user.email}
                          onChange={(e) => {
                            e.preventDefault();
                            let aux = { ...user };
                            aux.email = e.target.value;
                            setUser(aux);
                            removeError("email");
                          }}
                          autoComplete="off"
                        />

                      </div>
                      {hasError("email") && (
                        <p className="error-msg">{getError("email").message}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="section">
                  <div className="section-wrapper">
                    <div className="left">
                      <p className="subtitle">Full Name</p>
                    </div>
                    <div className="right">
                      <div
                        className={`input ${hasError("name") ? "error" : ""}`}
                      >
                        <div className="input-icon">
                          <FaUser />
                        </div>
                        <input
                          type="text"
                          placeholder="Name"
                          name="name"
                          value={user.name}
                          onChange={(e) => {
                            e.preventDefault();
                            let aux = { ...user };
                            aux.name = e.target.value;
                            setUser(aux);
                            removeError("name");
                          }}
                          autoComplete="off"
                        />

                      </div>
                      {hasError("name") && (
                        <p className="error-msg">{getError("name").message}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="section">
                  <div className="section-wrapper">
                    <div className="left">
                      <p className="subtitle">Password</p>
                      {!id && (
                        <p className="light-text">
                          If left empty, the user will get an email requesting
                          they set a password.
                        </p>
                      )}
                    </div>
                    <div className="right">
                      <div className="input">
                        <div className="input-icon">
                          <FaLock />
                        </div>
                        <input
                          type={showPassword ? "text" : "password"}
                          name="password"
                          placeholder="Password"
                          onChange={(e) => {
                            e.preventDefault();
                            let aux = { ...user };
                            aux.password = e.target.value;
                            setUser(aux);
                          }}
                          autoComplete="off"
                        />

                        <div
                          className="input-icon"
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                        >
                          {showPassword ? <FaEyeSlash /> : <FaEye />}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div id="access">
                <div className="title section">
                  <h3>Access</h3>
                </div>
                <div className="section">
                  <div className="section-wrapper">
                    <div className="left">
                      <p className="subtitle">Position</p>
                      <p className="light-text">
                        {user.role === 1
                          ? "(Admin has access to all clients)"
                          : ""}
                      </p>
                    </div>
                    <div className="right">
                      <div
                        className={`select-wrapper ${hasError("role") ? "error" : ""
                          }`}
                      >
                        <Select
                          styles={formSelectStyles}
                          onChange={(value) => {
                            let aux = { ...user };
                            aux.role = value.value;
                            setUser(aux);
                            if (value.value) removeError("role");
                          }}
                          options={
                            role() == 1
                              ? [
                                { label: "Admin", value: 1 },
                                { label: "Manager", value: 2 },
                                { label: "Office Manager", value: 6 },
                                { label: "AGF", value: 7 },
                              ]
                              : [{ label: "Manager", value: 2 }]
                          }
                          className="custom-select"
                        ></Select>
                      </div>
                      {hasError("role") && (
                        <p className="error-msg">{getError("role").message}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className="section"
                  style={
                    user.role !== 2 && user.role !== "2" && user.role !== 7 && user.role !== "7"
                      ? { display: "none" }
                      : {}
                  }
                >
                  <div className="section-wrapper">
                    <div className="left">
                      <p className="subtitle">Clients</p>
                    </div>

                    <div className="right autocomplete">
                      <div className="input">
                        <input
                          type="text"
                          value={searchedClient}
                          onChange={(e) => {
                            e.preventDefault();
                            setSearchedClient(e.target.value);
                          }}
                          placeholder="Start typing..."
                        />
                      </div>

                      <div className="client-list">
                        {fetchedClients(searchedClient)}
                      </div>

                      <div className="available-clients">
                        {user?.clients?.map((client, i) => {
                          return (
                            <div className="client-box" key={i}>
                              {client}
                              <span
                                onClick={() => {
                                  handleDeleteClient(client);
                                }}
                              >
                                <FaTimes />
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              {success && <SuccessMessage />}
              <div className="buttons">
                <button id="save-btn" type="submit">
                  {loading ? (
                    <LoadingIcon />
                  ) : (
                    <>{id ? "Update" : "Add User"}</>
                  )}
                </button>
                {id && (
                  <DeleteUser
                    id={id}
                    from={"individual"}
                    removeUser={() => navigate("/users")}
                  />
                )}
              </div>
            </form>
          </div>
        </>
      )}
    </>
  );
};
