import Swal from "sweetalert2";

const loadingAnimation = (state) => {
  if (state === true) {
    Swal.fire({
      customClass: {
        container:
          "custom-loading-animation-swal-container delete-client-overlay",
        popup: "custom-loading-animation-swal-popup",
      },
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  } else if (state === false) {
    Swal.close();
  } else {
    Swal.close();
  }
};

export { loadingAnimation };
