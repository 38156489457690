import { Title } from "./Title";
export const ErrorBoundaryComponent = () => {
  return (
    <>
      <Title title="Error :(" />
      <div id="error" className="page-content">
        <h1>
          ERROR <br /> THERE HAS BEEN AN ERROR, TRY AGAIN!
        </h1>
        <h1>😢</h1>
      </div>
    </>
  );
};
