import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import Cookies from "universal-cookie";

import home_logo from "../assets/nav-bar-icons/home-logo.svg";
import shipments_logo from "../assets/nav-bar-icons/shipments-logo.svg";
import billing_logo from "../assets/nav-bar-icons/billing-logo.svg";
import reports_logo from "../assets/nav-bar-icons/reports-logo.svg";
import settings_logo from "../assets/nav-bar-icons/settings-logo.svg";

import Tooltip from "./common/Tooltip.jsx";

import "../stylesheets/Sidebar.scss";
import { TbLogout } from "react-icons/tb";
import { FaUserShield, FaEllipsisH } from "react-icons/fa";
import { HiUsers } from "react-icons/hi";
import {
  URL,
  token,
  role,
  resetToken,
  resetRole,
} from "../components/Utils.jsx";
import { loadingAnimation } from "./scripts/functions.js";
const Sidebar = () => {
  const navLinkStyles = ({ isActive }) => {
    return {
      opacity: isActive ? "1" : ".5",
    };
  };

  const navigate = useNavigate();
  const cookies = new Cookies();

  const tooltipToggleRef = useRef(null);

  const [actTooltip, setActTooltip] = useState(false);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (!token() || token() == null) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    if (!token() || token() == null) {
      navigate("/login");
    }
  }, [cookies]);

  const logout = async () => {
    try {
      loadingAnimation(true);
      const resp = await fetch(`${URL}/admin/logout`, {
        method: "POST",
        headers: { Authorization: `Bearer ${token()}` },
      });
      const data = await resp.json();
      if (resp.status == 200) {
        cookies.remove("token", { path: "/" });
        cookies.remove("name", { path: "/" });
        cookies.remove("role", { path: "/" });
        resetToken();
        resetRole();
        loadingAnimation(false);
        navigate("/login");
      } else {
        console.log("error logging out");
        loadingAnimation(false);
      }
    } catch (e) {
      console.log(e);
      loadingAnimation(false);
    }
  };

  const location = useLocation().pathname;

  useEffect(() => {
    if (window.innerWidth < 600) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
    const handleResize = (e) => {
      if (e.target.innerWidth < 600) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    // Bind the event listener
    const navBarTooltipListener = (e, element) => {
      if (element.current && !element.current.contains(e.target)) {
        const tooltip = element?.current.querySelector(
          ".custom-tooltip-container"
        );
        if (tooltip.classList.contains("active-tooltip")) {
          setActTooltip(false);
        }
      }
    };

    document.addEventListener("mousedown", (e) => {
      navBarTooltipListener(e, tooltipToggleRef);
    });
    return () => {
      document.removeEventListener("mousedown", (e) => {
        navBarTooltipListener(e, tooltipToggleRef);
      });
    };
  }, []);

  return (
    <aside className="navbar">
      <NavLink to="/">
        <img
          src={require("../assets/atlantic-main-logo.png")}
          alt="main-logo"
          id="nav-bar_main-img"
        />
      </NavLink>
      <ul className="p-relative">
        {(role() != 7) && (
          <>
            <li className="navbar-link">
              <NavLink to="/" style={navLinkStyles}>
                <img
                  src={home_logo}
                  alt="home"
                  id="home-logo"
                  className="nav-logo"
                />
              </NavLink>
            </li>
          </>
        )}

        <li className="navbar-link">
          <NavLink to="/shipments" style={navLinkStyles}>
            <img
              src={shipments_logo}
              alt="shipments"
              id="shipments-logo"
              className="nav-logo"
            />
          </NavLink>
        </li>

        {role() == 1 && (
          <li className="navbar-link">
            <NavLink to="/clients" style={navLinkStyles}>
              <HiUsers />
            </NavLink>
          </li>
        )}

        {(role() != 7) && (
          <>
            <li className={`navbar-link ${isMobile ? "no-display" : ""}`}>
              <NavLink to="/billing" style={navLinkStyles}>
                <img
                  src={billing_logo}
                  alt="billing"
                  id="billing-logo"
                  className="nav-logo"
                />
              </NavLink>
            </li>
          </>
        )}
        {(role() != 7) && (
          <>

            <li className={`navbar-link ${isMobile ? "no-display" : ""}`}>
              <NavLink to="/reports" style={navLinkStyles}>
                <img
                  src={reports_logo}
                  alt="reports"
                  id="reports-logo"
                  className="nav-logo"
                />
              </NavLink>
            </li>
          </>
        )}


        {(role() == 1 || role() == 6) && (
          <li className={`navbar-link ${isMobile ? "no-display" : ""}`}>
            <NavLink to="/users" style={navLinkStyles}>
              <FaUserShield />
            </NavLink>
          </li>
        )}

        {(role() != 7) && (
          <>

            <li className={`navbar-link ${isMobile ? "no-display" : ""}`}>
              <NavLink to="/settings" style={navLinkStyles}>
                <img
                  src={settings_logo}
                  alt="settings"
                  id="settings-logo"
                  className="nav-logo"
                />
              </NavLink>
            </li>
          </>
        )}

        {isMobile && (
          <li
            ref={tooltipToggleRef}
            className="navbar-link p-relative"
            onClick={() => {
              if (actTooltip === false) {
                setActTooltip(true);
              } else if (actTooltip === true) {
                setActTooltip(false);
              }
            }}
          >
            <a className="logout-btn">
              <FaEllipsisH />
            </a>

            <Tooltip
              myComponents={[
                <NavLink to="/billing" style={navLinkStyles}>
                  <img
                    src={billing_logo}
                    alt="billing"
                    id="billing-logo"
                    className="nav-logo"
                  />
                </NavLink>,
                <NavLink to="/reports" style={navLinkStyles}>
                  <img
                    src={reports_logo}
                    alt="reports"
                    id="reports-logo"
                    className="nav-logo"
                  />
                </NavLink>,
                <NavLink to="/settings" style={navLinkStyles}>
                  <img
                    src={settings_logo}
                    alt="settings"
                    id="settings-logo"
                    className="nav-logo"
                  />
                </NavLink>,
                role() == 1 || role() == 6 ? (
                  <NavLink to="/users" style={navLinkStyles}>
                    <FaUserShield />
                  </NavLink>
                ) : (
                  ""
                ),
              ]}
              activeTooltip={actTooltip}
            />
          </li>
        )}

        <li className="navbar-link">
          <a className="logout-btn" onClick={logout}>
            <TbLogout />
          </a>
        </li>
      </ul>
    </aside>
  );
};
export default Sidebar;
