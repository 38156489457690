import { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { Title, LoadingTitle } from "../../components/Title.jsx";
import {
  URL,
  token,
  homeShipmentStatusOptions,
  shipmentDateOptions,
  customSelectStyles,
  statusSelectStyles,
  getDateRangeFilter,
} from "../../components/Utils.jsx";
import { Box, LoadingBox } from "../../components/home/Box.jsx";
import {
  ShipmentsTableSearch,
  LoadingTable,
} from "../../components/table/shipments/ShipmentTable.jsx";
import { toast } from "react-toastify";

import Select from "react-select";

//---------------------------STYLES------------------------------
import "../../App.scss";
import "../../stylesheets/Home.scss";
import GlobalAlert from "../../components/GlobalAlert.jsx";

const Home = () => {
  const cookies = new Cookies();
  const name = cookies.get("name");
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [shipments, setShipments] = useState();

  const [pendingDocs, setPendingDocs] = useState();
  const [docsUploaded, setDocsUploaded] = useState();
  const [underExam, setUnderExam] = useState();
  const [fdaExam, setFdaExam] = useState();
  const [etamanuallyedited, setEtaManuallyEdited] = useState();
  const [unconfirmedDeliveries, setUnconfirmedDeliveries] = useState();
  const [totalShipments, setTotalShipments] = useState();

  const [clients, setClients] = useState([]);

  /* Get homepage shipments */
  const [perPage, setPerPage] = useState();
  const [maxPages, setMaxPages] = useState();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [globalNotification, setGlobalNotification] = useState([]);
  const [extraUrl, setExtraUrl] = useState({
    page,
    search: "",
    cust: "",
    status: "",
    date: "",
    ordering: "0,0",
  });
  const [finalUrl, setFinalUrl] = useState(`${URL}/admin/home?`);

  useEffect(() => {
    const searchParams = new URLSearchParams();
    for (const key of Object.keys(extraUrl)) {
      if (extraUrl[key].length > 0 || typeof extraUrl[key] === "number") {
        searchParams.append(key, extraUrl[key]);
      }
    }
    let aux = `${URL}/admin/home?${searchParams}`;
    setFinalUrl(aux);
  }, [extraUrl]);

  useEffect(() => {
    getShipments();
  }, [finalUrl, page]);

  const getNotification = async () => {
    fetch(`${URL}/admin/get_notification`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token()}` },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && data?.length > 0) {
          setGlobalNotification((oldArray) => [...oldArray, data[0]]);
        }
      })
      .catch((error) => {
        return error;
      });
  };

  const handleNotificationDismiss = () => {
    setGlobalNotification([]);
  };

  const getShipments = async () => {
    try {
      const response = await fetch(finalUrl + `&home=true&page=${page}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token()}`,
          Accept: "application/json",
        },
      });
      const data = await response.json();
      if (response.status == 200) {
        setPendingDocs(data.pending_docs);
        setDocsUploaded(data.documents_uploaded);
        setUnderExam(data.under_exam);
        setFdaExam(data.fda_exam);
        setUnconfirmedDeliveries(data.unconfirmed_delivery_orders);
        setEtaManuallyEdited(data.eta_manually_edited);
        setShipments(data.result.data);
        setMaxPages(data.result.total);
        setTotalShipments(data.result.total);
        setPerPage(data.result.to);
        setLoading(false);
      } else if (response.status == 401) {
        cookies.remove("token");
        navigate("/login");
      }
    } catch (e) {
      console.log(e);
    }
  };

  /* Get clients for dropdown */
  const getClients = async () => {
    try {
      const response = await fetch(`${URL}/admin/all_clients?all=true`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token()}`,
          Accept: "application/json",
        },
      });
      const data = await response.json();
      if (response.status == 200) {
        if (data.result.length > 0) {
          setClients(data.result);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getClients();
  }, []);

  useEffect(() => {
    getNotification();
  }, []);

  /* Shipment pagination */
  const gotoPreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };
  const gotoNextPage = () => {
    if (page < maxPages) {
      setPage(page + 1);
    }
  };

  const clientsList = () => {
    let ret = [{ value: "all", label: "All Clients" }];
    clients.map((client) => {
      ret.push({
        value: client.account_no.toLowerCase(),
        label: client.account_no,
      });
    });
    return ret;
  };

  const handleOrder = (value) => {
    setExtraUrl({ ...extraUrl, ordering: value });
  };

  const setGreeting = (user_name) => {
    // String variable initialization
    let result_string;
    // Date Variables
    const current_date = new Date();
    const hours = current_date.getHours();
    const minutes = current_date.getMinutes();
    const formattedHours = hours < 10 ? "0" + hours : hours;
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    // Switch statement to create result string
    switch (true) {
      case formattedHours >= 0 && formattedHours < 12:
        result_string = `Good morning, ${user_name ? user_name : "User"}`;
        break;
      case formattedHours >= 12 && formattedHours < 18:
        result_string = `Good afternoon, ${user_name ? user_name : "User"}`;
        break;
      case formattedHours >= 18 && formattedHours <= 23:
        result_string = `Good evening, ${user_name ? user_name : "User"}`;
        break;
      default:
        result_string = `Good day, ${user_name ? user_name : "User"}`;
    }

    // Return of result string to be used by component
    return result_string;
  };

  return (
    <>
      {loading || typeof shipments === "undefined" ? (
        <>
          <LoadingTitle />
          <div id="home" className="page-content">
            <div className="boxes">
              <LoadingBox />
              <LoadingBox />
              <LoadingBox />
              <LoadingBox />
              <LoadingBox />
            </div>
            <div className="data-table">
              <div className="header" style={{ marginBottom: "12px" }}>
                <h2>Shipments</h2>
                <div className="filters">
                  <Select
                    styles={statusSelectStyles}
                    defaultValue={"all"}
                    onChange={(e) => {
                      let value = e.value;
                      value === "all"
                        ? setExtraUrl({ ...extraUrl, status: "" })
                        : setExtraUrl({ ...extraUrl, status: value });
                    }}
                    options={homeShipmentStatusOptions}
                    className="custom-select"
                    placeholder={"All Shipments"}
                  />

                  {clients ? (
                    <Select
                      styles={customSelectStyles}
                      defaultValue={"all"}
                      onChange={(e) => {
                        let value = e.value;
                        value === "all"
                          ? setExtraUrl({ ...extraUrl, cust: "" })
                          : setExtraUrl({ ...extraUrl, cust: value });
                      }}
                      options={clientsList()}
                      className="custom-select"
                      placeholder={"All Clients"}
                    />
                  ) : null}

                  <Select
                    styles={customSelectStyles}
                    defaultValue={"all"}
                    onChange={(e) => {
                      e.value === "all"
                        ? setExtraUrl({
                            ...extraUrl,
                            date: getDateRangeFilter("all"),
                          })
                        : setExtraUrl({
                            ...extraUrl,
                            date: getDateRangeFilter(e.value),
                          });
                    }}
                    options={shipmentDateOptions}
                    className="custom-select"
                    placeholder={"All Dates"}
                  />
                </div>
              </div>
              <LoadingTable />
            </div>
          </div>
        </>
      ) : (
        <>
          <Title title={setGreeting(name)} />
          <div id="home" className="page-content">
            <GlobalAlert
              notification={globalNotification}
              onDismiss={handleNotificationDismiss}
            />
            <div className="boxes">
              <NavLink to={`/shipments/?status=pending documents&home=1`}>
                <Box number={pendingDocs} text="Pending" text2="Documents" />
              </NavLink>
              <NavLink to={`/shipments/?status=pending release&home=1`}>
                <Box number={docsUploaded} text="Documents" text2="Uploaded" />
              </NavLink>
              <NavLink
                to={`/shipments/?custom=unconfirmed-delivery-orders&home=1`}
              >
                <Box
                  number={unconfirmedDeliveries}
                  text="Pending"
                  text2="Delivery Order"
                />
              </NavLink>
              <NavLink to={`/shipments/?custom=cbp-review&home=1`}>
                <Box number={underExam} text="CBP" text2="Hold/Exam" />
              </NavLink>
              <NavLink to={`/shipments/?custom=fda-review&home=1`}>
                <Box number={fdaExam} text="FDA" text2="Hold/Exam" />
              </NavLink>
              <NavLink to={`/shipments/?custom=eta-manually-edited&home=1`}>
                <Box number={etamanuallyedited} text="ETA" text2="Updates" />
              </NavLink>
            </div>
            <div className="data-table">
              <div className="header" style={{ marginBottom: "12px" }}>
                <h2>Shipments</h2>
                <div className="filters">
                  <Select
                    styles={statusSelectStyles}
                    defaultValue={"all"}
                    onChange={(e) => {
                      let value = e.value;
                      value === "all"
                        ? setExtraUrl({ ...extraUrl, status: "" })
                        : setExtraUrl({ ...extraUrl, status: value });
                    }}
                    options={homeShipmentStatusOptions}
                    className="custom-select"
                    placeholder={"All Shipments"}
                  />

                  {clients ? (
                    <Select
                      styles={customSelectStyles}
                      defaultValue={"all"}
                      onChange={(e) => {
                        let value = e.value;
                        value === "all"
                          ? setExtraUrl({ ...extraUrl, cust: "" })
                          : setExtraUrl({ ...extraUrl, cust: value });
                      }}
                      options={clientsList()}
                      className="custom-select"
                      placeholder={"All Clients"}
                    />
                  ) : null}

                  <Select
                    styles={customSelectStyles}
                    defaultValue={"all"}
                    onChange={(e) => {
                      e.value === "all"
                        ? setExtraUrl({
                            ...extraUrl,
                            date: getDateRangeFilter("all"),
                          })
                        : setExtraUrl({
                            ...extraUrl,
                            date: getDateRangeFilter(e.value),
                          });
                    }}
                    options={shipmentDateOptions}
                    className="custom-select"
                    placeholder={"All Dates"}
                  />
                </div>
              </div>
              <div className="table-parts">
                <ShipmentsTableSearch
                  shipments={shipments}
                  order={handleOrder}
                  order_value={extraUrl.ordering}
                />
                <div className="pagination">
                  {page != 1 && (
                    <button onClick={gotoPreviousPage}>{"< Previous"}</button>
                  )}
                  <span className="text">
                    {page == maxPages
                      ? `${totalShipments}/${totalShipments}`
                      : `${perPage}/${totalShipments}`}
                  </span>
                  {perPage != totalShipments && (
                    <button onClick={gotoNextPage}>{"Next >"}</button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default Home;
