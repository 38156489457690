import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Select from "react-select";
import { BsSearch } from "react-icons/bs";
import { BiEditAlt } from "react-icons/bi";
import { FaAngleDown, FaAngleUp, FaUserPlus } from "react-icons/fa";
import { toast } from "react-toastify";

import {
  URL,
  getDateRangeFilter,
  shipmentStatusOptions,
  shipmentDateOptions,
  customSelectStyles,
  token,
  LoadingTable,
  fixedFormat,
} from "../../components/Utils";
import { UserTitle } from "../../components/Title";
import { DeleteClient } from "./DeleteClient";

import "../../stylesheets/Users.scss";

export const Clients = () => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState();

  const [finalUrl, setFinalUrl] = useState(`${URL}/admin/all_clients?`);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState();
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const [extraUrl, setExtraUrl] = useState({
    page,
    search: "",
    cust: 1,
    tot: "",
    date: "",
    ordering: "",
  });

  const getUsers = async () => {
    setLoading(true);
    try {
      const resp = await fetch(finalUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });
      const data = await resp.json();
      if (resp.status == 200) {
        setUsers(data.result.data);
        setTotalUsers(data.result.total);
        setTotalPages(data.result.last_page);
        setPerPage(data.result.per_page);
        setLoading(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams();

    for (const key of Object.keys(extraUrl)) {
      if (extraUrl[key].length > 0 || typeof extraUrl[key] === "number") {
        searchParams.append(key, extraUrl[key]);
      }
    }

    let aux = `${URL}/admin/all_clients?${searchParams}`;

    setFinalUrl(aux);
  }, [extraUrl]);

  useEffect(() => {
    getUsers();
  }, [finalUrl]);

  const handleSearch = (e) => {
    e.preventDefault();
    let aux = { ...extraUrl };
    aux.search = `${encodeURIComponent(e.target.value)}`;
    if (e.target.value.length == 0) {
      aux.search = "";
    }

    aux.page = 1;
    setExtraUrl(aux);
  };

  const handleCustomerFilter = (e) => {
    let aux = { ...extraUrl };
    if (e.value === "all_customers") {
      aux.cust = "";
    } else {
      aux.cust = e.value;
    }
    aux.page = 1;
    setExtraUrl(aux);
  };

  const handleShipmentsFilter = (e) => {
    let aux = { ...extraUrl };
    if (e.value === "all_shipments") {
      aux.tot = "";
    } else {
      aux.tot = `3,>=,${e.value}`;
    }

    aux.page = 1;
    setExtraUrl(aux);
  };

  const gotoPreviousPage = () => {
    let aux = { ...extraUrl };
    if (page >= 1) {
      setPage(page - 1);
      aux.page = page - 1;
      setExtraUrl(aux);
    }
  };

  const gotoNextPage = () => {
    let aux = { ...extraUrl };
    if (page < totalPages) {
      setPage(page + 1);
      aux.page = page + 1;
      setExtraUrl(aux);
    }
  };

  const enableUser = async (id) => {
    try {
      const resp = await fetch(`${URL}/admin/users/${id}/enable`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });
      const data = await resp.json();
      if (resp.status == 200) {
        getUsers();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleDelete = (id) => {
    setUsers(users.filter((u) => u.id != id));
  };

  return (
    <>
      <div className="top">
        <UserTitle title={`Clients`} />
      </div>
      <div id="clients" className="page-content">
        <div className="data-table">
          <div className="header" style={{ marginBottom: "12px" }}>
            <div className="search-input">
              <input
                type="text"
                className="search-shipments"
                onChange={handleSearch}
              />
              <div className="icon">
                <BsSearch />
              </div>
            </div>
            <div className="filters">
              <Select
                styles={customSelectStyles}
                defaultValue={"all_customers"}
                onChange={handleCustomerFilter}
                options={[
                  { label: "All Users", value: "all_customers" },
                  { label: "Disabled Users", value: "0" },
                  { label: "Enabled Users", value: "1" },
                ]}
                className="custom-select"
                placeholder={"All Users"}
              />

              <Select
                styles={customSelectStyles}
                defaultValue={"all_customers"}
                onChange={handleShipmentsFilter}
                options={[
                  { label: "All Shipments", value: "all_customers" },
                  { label: "5 or more", value: "5" },
                  { label: "10 or more", value: "10" },
                  { label: "20 or more", value: "20" },
                  { label: "50 or more", value: "50" },
                  { label: "100 or more", value: "100" },
                  { label: "175 or more", value: "175" },
                  { label: "250 or more", value: "250" },
                ]}
                className="custom-select"
                placeholder={"All-Shipments"}
              />

              <Select
                styles={customSelectStyles}
                defaultValue={"all"}
                onChange={(e) => {
                  setExtraUrl({
                    ...extraUrl,
                    date: getDateRangeFilter(e.value),
                  });
                }}
                options={shipmentDateOptions}
                className="custom-select"
                placeholder={"All Dates"}
              />
            </div>
          </div>

          {loading || typeof users == "undefined" ? (
            <LoadingTable />
          ) : (
            <table>
              <thead>
                <tr>
                  <th
                    onClick={() => {
                      let aux = { ...extraUrl };
                      aux.ordering = aux.ordering === "0,0" ? "1,0" : "0,0";
                      setExtraUrl(aux);
                    }}
                  >
                    Account No
                    <span className="sort-arrow">
                      {extraUrl.ordering === "0,0" ? (
                        <FaAngleUp />
                      ) : (
                        <FaAngleDown />
                      )}
                    </span>
                  </th>
                  <th
                    onClick={() => {
                      let aux = { ...extraUrl };
                      aux.ordering = aux.ordering === "0,1" ? "1,1" : "0,1";
                      setExtraUrl(aux);
                    }}
                  >
                    Email Address
                    <span className="sort-arrow">
                      {extraUrl.ordering === "0,1" ? (
                        <FaAngleUp />
                      ) : (
                        <FaAngleDown />
                      )}
                    </span>
                  </th>
                  <th
                    onClick={() => {
                      let aux = { ...extraUrl };
                      aux.ordering = aux.ordering === "0,2" ? "1,2" : "0,2";
                      setExtraUrl(aux);
                    }}
                  >
                    Date Created
                    <span className="sort-arrow">
                      {extraUrl.ordering === "0,2" ? (
                        <FaAngleUp />
                      ) : (
                        <FaAngleDown />
                      )}
                    </span>
                  </th>
                  <th
                    onClick={() => {
                      let aux = { ...extraUrl };
                      aux.ordering = aux.ordering === "0,3" ? "1,3" : "0,3";
                      setExtraUrl(aux);
                    }}
                  >
                    Shipments
                    <span className="sort-arrow">
                      {extraUrl.ordering === "0,3" ? (
                        <FaAngleUp />
                      ) : (
                        <FaAngleDown />
                      )}
                    </span>
                  </th>
                  <th style={{ textAlign: "center" }}>Actions</th>
                </tr>
              </thead>
              {loading || typeof users === "undefined" ? (
                <tbody></tbody>
              ) : (
                <ClientsTable
                  users={users}
                  enableUser={enableUser}
                  handleDelete={(id) => handleDelete(id)}
                />
              )}
            </table>
          )}
          {loading || typeof users === "undefined" ? (
            <></>
          ) : (
            <div className="pagination">
              {page != 1 && (
                <button onClick={gotoPreviousPage}>{"< Previous"}</button>
              )}
              <span className="text">
                {page * perPage < totalUsers ? page * perPage : totalUsers}/
                {totalUsers}
              </span>
              {page != totalPages && (
                <button onClick={gotoNextPage}>{"Next >"}</button>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const ClientsTable = ({ users, enableUser, handleDelete }) => {
  return (
    <tbody>
      {users.map((user, i) => {
        return (
          <tr key={i}>
            <td style={{ fontWeight: "600" }}>
              <NavLink to={`/clients/edit-client/${user.id}`}>
                {user.account_no}
              </NavLink>
            </td>
            <td>{user.email}</td>
            <td>{fixedFormat(new Date(user?.created_at))}</td>
            <td>{user.num_shipments}</td>
            <td className="right-aligned actions-body">
              <div className="actions">
                <div className="edit">
                  <span>
                    <NavLink to={`/clients/edit-client/${user.id}`}>
                      <BiEditAlt />
                    </NavLink>
                  </span>
                </div>
                <div className="delete">
                  {/* remove "!" when "active" is being returned in client object */}
                  {user.active ? (
                    <DeleteClient
                      id={user.id}
                      from={"table"}
                      isDeleted={(id) => handleDelete(id)}
                    />
                  ) : (
                    <span
                      onClick={() => {
                        enableUser(user.id);
                      }}
                      className="enable-client"
                    >
                      <FaUserPlus />
                    </span>
                  )}
                </div>
              </div>
            </td>
          </tr>
        );
      })}
    </tbody>
  );
};
