import React from "react";
import "../../stylesheets/components/_tooltip.scss";

const Tooltip = ({ myComponents, activeTooltip }) => {
  return (
    <div
      className={`custom-tooltip-container bubble ${
        activeTooltip ? "active-tooltip" : "inactive-tooltip"
      }`}
    >
      {myComponents?.length > 0 &&
        myComponents.map((component, index) => (
          <div className="custom-tooltip-element" key={index}>
            {component}
          </div>
        ))}
    </div>
  );
};

export default Tooltip;
