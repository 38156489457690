import React from "react";
import { useNavigate } from "react-router-dom";
import { HiOutlineTrash } from "react-icons/hi";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import { URL, token, navigate } from "../../../components/Utils";

import "../../../stylesheets/DeleteSubuser.scss";
import { toast } from "react-toastify";
import { loadingAnimation } from "../../../components/scripts/functions";

export const DeleteUser = ({ id, from, client, reload }) => {
  const navigate = useNavigate();

  const deleteClient = async () => {
    loadingAnimation(true);
    try {
      const resp = await fetch(`${URL}/admin/users/subusers/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });

      const data = await resp.json();
      if (resp.status == 200) {
        loadingAnimation(false);
        if (reload) {
          reload();
        } else {
          navigate(`/clients/edit-client/${client}`);
        }
      } else {
        toast.error(
          "Something went wrong. Please, try again in a few minutes!"
        );
      }
    } catch (e) {
      console.log(e);
      loadingAnimation(false);
      toast.error("Something went wrong. Please, try again in a few minutes!");
    }
  };

  const submit = () => {
    confirmAlert({
      title: "Confirm to delete.",
      message: "Are you sure you want to delete this User?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteClient(),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
      overlayClassName: "delete-client-overlay",
      closeOnEscape: true,
      closeOnClickOutside: true,
      keyCodeForClose: [8, 32],
    });
  };

  const Button = () => {
    return <button className="delete-user-button white red-bg">Delete</button>;
  };

  return (
    <div className="container" id="delete-user" onClick={submit}>
      {from === "table" ? <HiOutlineTrash /> : <Button />}
    </div>
  );
};
