import React from "react";
import { useNavigate } from "react-router-dom";
import { HiOutlineTrash } from "react-icons/hi";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import { URL, token, navigate } from "../../components/Utils";

import "../../stylesheets/DeleteClient.scss";
import { toast } from "react-toastify";
import { loadingAnimation } from "../../components/scripts/functions";

export const DeleteUser = ({ id, from, removeUser }) => {
  const navigate = useNavigate();

  const deleteClient = async () => {
    loadingAnimation(true);
    try {
      const resp = await fetch(`${URL}/admin/managers/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      });

      const data = await resp.json();
      if (resp.status == 200) {
        removeUser(id);
        // from === "table" ? navigate(0) : navigate("/users")
        loadingAnimation(false);
      } else {
        toast.error("Something went wrong. Please, try again in a few minutes!");
        loadingAnimation(false);
      }
    } catch (e) {
      console.log(e);
      toast.error("Something went wrong. Please, try again in a few minutes!");
      loadingAnimation(false);
    }
  };

  const submit = () => {
    confirmAlert({
      title: "Confirm to delete.",
      message: "Are you sure you want to delete this User?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteClient(),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
      overlayClassName: "delete-client-overlay",
      closeOnEscape: true,
      closeOnClickOutside: true,
      keyCodeForClose: [8, 32],
    });
  };

  const Button = () => {
    return (
      <button
        className="delete-user-button"
        style={{ background: "var(--red)" }}
      >
        Delete
      </button>
    );
  };

  return (
    <div className="container" id="delete-user">
      <div onClick={submit}>
        {from === "table" ? <HiOutlineTrash /> : <Button />}
      </div>
    </div>
  );
};
