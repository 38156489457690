import { useState } from "react";
import "../stylesheets/GlobalAlert.scss";
import { IoCloseSharp } from "react-icons/io5";

const GlobalAlert = (props) => {
  return (
    <>
      {props?.notification && props?.notification?.length > 0 && (
        <div className="global-alert-container">
          <div className="global-alert-text">
            <p className="global-alert-text">
              {props?.notification[0]?.message}
            </p>
          </div>
          <a className="global-alert-close" onClick={() => props?.onDismiss()}>
            <IoCloseSharp />
          </a>
        </div>
      )}
    </>
  );
};

export default GlobalAlert;
